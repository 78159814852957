import { urls, TYPE } from '../reducers/seoPopularSearches';
import { getSearches } from 'Selectors/seo';
import { getSeoConfigParams, configToObject } from 'Helpers/seo';
import { parseParams } from 'Helpers/seoPopularSearches';
import { pickBy } from 'Helpers/objects';
import { getSEOLang } from 'Selectors/config';

export const getPopularSearches = (config, params) => (dispatch, getState, { get }) => {
    if (params.text) {
        return Promise.resolve();
    }

    const settings = configToObject(config);

    if (!Object.prototype.hasOwnProperty.call(settings, 'SEO')) {
        return Promise.resolve();
    }

    const type = `ELEMENT_${TYPE}`;
    const state = getState();
    const lang = getSEOLang(state);

    const reqParams = {
        ...getSeoConfigParams(config, lang),
        ...parseParams(settings, params)
        // Filters are not used by mystique to send popular searches
        // ...parseQuery(query)
    };

    if (
        getSearches(state, { ...reqParams, key: 'isFetching' })
        || getSearches(state, reqParams, { ...reqParams, key: 'isError' })
        || getSearches(state, reqParams)
    ) {
        return Promise.resolve();
    }

    const url = urls.getPopularSearches();

    return dispatch(get(url, type, pickBy(reqParams)));
};
