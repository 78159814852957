/* eslint-disable max-lines */
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withRouter from 'HOCs/withRouter';

import ReactObserver from 'Components/ReactObserver/ReactObserver';
import { buildURL, getItemUrl, getItemUrlMobbi } from 'Helpers/url';
import ItemImage from './ItemImage';

import { FormattedMessage as Translation } from 'react-intl';
import classNames from 'classnames';

import { isEmpty } from 'Helpers/objects';
import { relativeDate } from 'Helpers/dates';
import { getItemLocation, getIsStoreInspectedCar, getIsFeaturedAd, isCocoFofoUser } from 'Helpers/item';

import withTrack from 'HOCs/withTrack/withTrack';
import withConfig from 'HOCs/withConfig/withConfig';

import ItemFavouriteIcon from 'Components/ItemFavouriteIcon/ItemFavouriteIcon';

import css from './ItemCard.scss';
import { css as uicss } from 'panamera-react-ui';
import { slugify } from 'Helpers/strings';
import { X, WEBP_SVG_TYPE, SVG_TYPE } from 'Constants/images';
import { ITEM_SOURCE, INSPECTION_INFO_STATUS } from 'Constants/items';
import { ITEM_CARD_TYPE, LISTING_TYPES, trustTagHash } from 'Constants/listing';
import { HOME, INSPECTED_CAR_ICN_CLICK } from 'Constants/tracking';
import withBackButtonHandling from 'HOCs/withBackButtonHandling/withBackButtonHandling';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import CallButton from '../components/CallButton/CallButton';
import { PLATFORM } from 'Constants/device.APP_TARGET';
import { noop } from 'Helpers/function';
import { THEMES } from 'Constants/bundles';
import { fileNames, HIGHLIGHT_TAG } from 'Constants/imageUrls';
import { configSelector } from 'Selectors/config';
import SpinViewTag from './SpinViewTag';
import { checkIfMxCL } from 'Helpers/isMxcl';
import {
    getLoggedUser
} from 'Selectors/user';
import { userSelector } from 'Selectors/users';
import { USER_TYPE } from 'Constants/users';
import { isMobile } from 'Helpers/devices';
import { CONFIG } from 'Constants/config';

import { emiNumberFormat } from 'Helpers/emiNumberFormat';
import TooltipInstallment from 'Components/TooltipInstallment/TooltipInstallment';
import MembershipTagV1 from 'Components/MembershipTagV1/MembershipTagV1';
import { shortPriceFormat } from 'Helpers/shortPriceDisplay';
import { FEATURED_TAG } from './constants';
import { getAIGeneratedAdsVideoConfig, showVideoBadgeBasedOnConfig } from 'Helpers/aiGeneratedAdsVideo';
const { icons } = uicss;

export class ItemCard extends React.PureComponent {
    static propTypes = {
        config: PropTypes.object.isRequired,
        item: PropTypes.object,
        type: PropTypes.string,
        onView: PropTypes.func,
        className: PropTypes.string,
        showStatistics: PropTypes.bool,
        friendsInCommon: PropTypes.bool,
        trackerOrigins: PropTypes.object,
        enabledRealImpressions: PropTypes.bool,
        visualizationType: PropTypes.oneOf([
            'grid', 'big', 'list'
        ]),
        listingType: PropTypes.string,
        onItemClick: PropTypes.func,
        getListingDescription: PropTypes.func,
        tag: PropTypes.string,
        isAdSuggested: PropTypes.bool,
        source: PropTypes.string,
        openModal: PropTypes.func.isRequired,
        // Added for tracking which ad clicked
        chosenOption: PropTypes.number,
        location: PropTypes.object,
        itemCardType: PropTypes.string,
        track: PropTypes.func,
        trackOrigin: PropTypes.string,
        showInspectionTag: PropTypes.bool,
        extraTrackAttr: PropTypes.object,
        imageHeight: PropTypes.number,
        imageWidth: PropTypes.number,
        generalConfig: PropTypes.object.isRequired,
        userType: PropTypes.string,
        loggedUser: PropTypes.object,
        sellerMembershiptype: PropTypes.string,
        memberTagCss: PropTypes.string,
        videoBadgeFeatureConfig: PropTypes.object
    };

    static defaultProps = {
        index: 0,
        item: {},
        className: '',
        type: 'public',
        onView: noop,
        showStatistics: false,
        friendsInCommon: false,
        visualizationType: 'grid',
        enabledRealImpressions: false,
        listingType: LISTING_TYPES.ITEMS,
        onItemClick: noop,
        getListingDescription: noop,
        tag: 'li',
        isAdSuggested: false,
        chosenOption: null,
        trackerOrigins: {},
        itemCardType: '',
        track: noop,
        trackOrigin: '',
        showInspectionTag: false,
        extraTrackAttr: {},
        videoBadgeFeatureConfig: null
    };

    constructor({ item, config, generalConfig }) {
        super();
        this.property = {
            slug: item && config.get('isOlxMobbi') ? getItemUrlMobbi(item.ad_id || item.id, item.title) : getItemUrl(item.ad_id || item.id, item.title),
            image: item && item.images && item.images[0] || {},
            price: item && item.price && item.price.value ? item.price.value.display : '',
            monthly_installment: item && item.price && item.price.value && item.price.value.emi && item.price.value.emi.monthly_installment && item.price.value.emi.monthly_installment.raw ? item.price.value.emi.monthly_installment.raw : 0,
            display: item && item.status && item.status.display,
            monetizerEnabled: config.get('monetizer').enabled,
            date: relativeDate(
                item.display_date || item.created_at_first || item.created_at,
                undefined,
                { suppressHydrationWarning: true }
            ),
            discountedPrice: item && item?.has_promotion && item?.price?.value?.discounted_price ? item.price.value.discounted_price.display : 0, // this field price item after discount
            discountAmount: item && item?.has_promotion && item?.price?.value?.discount_amount ? item.price.value.discount_amount.raw : 0,
            priceBeforeDiscount: item && item.price && item.price.value ? item.price.value.raw : 0
        };
        const adpvConfig = config.get('adpvAuto');

        this.carInspectedIconUri = adpvConfig && adpvConfig.carInspectedIconUri;
        this.enableNewAdpvFromConfig = adpvConfig && adpvConfig.enable;
        this.carCategory = adpvConfig && adpvConfig.carCategory;
        this.siteCode = config.get('siteCode');
        this.isLatamRegion = config.get('region') === 'latam';
        this.showFavBtn = adpvConfig && adpvConfig.showFavBtn;
        this.featuredTag = adpvConfig && adpvConfig.featuredTag;
        this.phoneInListing = generalConfig?.features?.find(feature => feature.name === 'olxautos_phone_in_listing') || {};
        this.theme = config.get('theme', 'id');
    }

    isVisible = viewingIt => {
        if (!this.props.enabledRealImpressions) {
            return;
        }
        if (viewingIt) {
            const { item } = this.props;
            const { id, title, user_id, monetizationInfo, spell, inspection_info } = item;
            const isFeatured = !!((monetizationInfo && !isEmpty(monetizationInfo.currentProduct))
                || (item.package && (item.package.id || item.package.name)));
            const { id: spell_id = 0 } = spell || {};
            const isInspected = !!getIsStoreInspectedCar(inspection_info);
            const impression = { id, title, user_id, isFeatured, spell_id, isInspected };

            this.props.onView(impression);
        }
    };

    getDefaultSize() {
        const sizeByVisualization = {
            list: 's=272x0',
            grid: 's=272x0',
            big: 's=644x0'
        };

        return sizeByVisualization[this.props.visualizationType];
    }

    renderStatus(display) {
        const { type } = this.props;

        if ((display === 'rejected' || display === 'modify') && type === 'private') {
            return {
                statusClass: css.rejected,
                status: <label><Translation id="rejected" /></label>
            };
        }
        if (display === 'deleted' && type === 'private') {
            return {
                statusClass: css.deleted,
                status: <label><Translation id="deleted" /></label>
            };
        }
        if (display === 'pending' && type === 'private') {
            return {
                statusClass: css.pending,
                status: <label><Translation id="pending" /></label>
            };
        }
        if (display === 'sold') {
            return {
                statusClass: css.sold,
                status: <label><Translation id="sold" /></label>
            };
        }
        if (display === 'outdated' && type === 'private') {
            return {
                statusClass: css.outdated,
                status: <label><Translation id="outdated" /></label>
            };
        }
        if (display === 'hot') {
            return {
                statusClass: css.hot,
                status: <label className={ `${icons['icon-Hot']} ${icons.panameraIcons}` }><Translation id="hot" /></label>
            };
        }
        if (display === 'new') {
            return {
                statusClass: css.new,
                status: <label><Translation id="new" /></label>
            };
        }
        return {};
    }

    onClick = () => {
        this.props.onItemClick(this.props.item);
    };

    getLinkToProp() {
        const { item, listingType, isAdSuggested, source, chosenOption = 'null', trackerOrigins, location } = this.props;
        const { slug } = this.property;
        const { feed_version = null } = this.props.extraTrackAttr;
        const state = {
            isAdSuggested,
            source
        };

        // Add fields for tracking related ads
        if (source === ITEM_SOURCE.RELATED) {
            const { id, scoreFactor = 'null' } = item;

            state.relatedAdTracking = {
                impressions: [{
                    adId: id,
                    scoreFactor
                }],
                chosen_option: chosenOption,
                origin: trackerOrigins.ITEM_PAGE
            };
        }
        // Add tracking for featured ad
        state.featuredAdTracking = {
            chosen_option: chosenOption
        };
        state.feed_version = feed_version;

        if (location && location.state && location.state.fromBundleResultset) {
            state.fromBundleResultset = location.state.fromBundleResultset;
        }
        return {
            pathname: listingType === LISTING_TYPES.RE_PORJECTS ? buildURL({ base: `/project/${item.ad_id || item.id}-${slugify(item.name)}-${slugify(item.locations.cityLabel)}` }) : slug,
            state
        };
    }

    handleTrustTagTap = ev => {
        ev.stopPropagation();
        const { track, trackOrigin, source, openModal } = this.props;

        track(INSPECTED_CAR_ICN_CLICK, {
            origin: trackOrigin,
            resultset_type: source,
            select_from: 'listing_page'
        });
        openModal();
    }

    showInspectionTag() {
        const { item: { category_id, inspection_info } = {}, listingType, showInspectionTag } = this.props;
        const isOLXID = this.siteCode === 'olxid';
        const isAdInspected = getIsStoreInspectedCar(inspection_info)
            && (!inspection_info.consent || inspection_info.consent === INSPECTION_INFO_STATUS.APPROVED);
        let enableNewAdpv = this.enableNewAdpvFromConfig;

        if (isOLXID) {
            enableNewAdpv = this.enableNewAdpvFromConfig && showInspectionTag;
        }
        else {
            enableNewAdpv = this.enableNewAdpvFromConfig;
        }
        return enableNewAdpv && category_id === this.carCategory && listingType === LISTING_TYPES.ITEMS && isAdInspected;
    }

    render() {
        const { item, userType, friendsInCommon, showStatistics, listingType, visualizationType, getListingDescription, tag, itemCardType, imageHeight, imageWidth, config, loggedUser, source, sellerMembershiptype, memberTagCss = '', videoBadgeFeatureConfig } = this.props;
        const { title, favorites, views, main_info, user_type, isSpinViewAvailable, user_id, has_promotion, is_video_generated, video_url } = item;
        const { image, price, display, date, monthly_installment, discountedPrice, discountAmount, priceBeforeDiscount } = this.property;
        const defaultSize = this.getDefaultSize();
        const itemLocation = getItemLocation(item);
        const isClassified = !checkIfMxCL(config);
        const isMyOwnAd = isClassified ? user_id !== loggedUser?.id : true;
        let { statusClass, status } = this.renderStatus(display);
        const isFeaturedAd = getIsFeaturedAd(item);
        const showFeaturedTag = this.featuredTag && isFeaturedAd;
        const staticAsseturl = config.get(CONFIG.STATIC_ASSETS);
        const olxautosTagImage = this.theme === THEMES.LETGO ? fileNames.OTOPLUS_SMALL_LOGO : fileNames.OLXAUTOS_BLUE_SMALL_LOGO;
        const enableCall = isCocoFofoUser(user_type || userType) && this.phoneInListing.enabled;
        const netPrice = has_promotion ? discountedPrice : price;
        const olxAutosTags = isCocoFofoUser(user_type || userType) && isClassified
            ? (<ImageWrapper
                fileName={ olxautosTagImage }
                types={ SVG_TYPE }
                className={ classNames(css.olxAutosTag, { [css.otoPlus]: this.theme === THEMES.LETGO && isMobile }) }
            />) : null;

        const isVideoAvailable = is_video_generated && video_url;
        const isSearchPage = source === ITEM_SOURCE.SEARCH || source === ITEM_SOURCE.BROWSE;
        const videoAvailableBadgeSRP = (<ImageWrapper
            addSVG
            types={ WEBP_SVG_TYPE }
            supportForDifferentSize
            className={ css.aiVideoBadgeSRPbrowse }
            fileName={ isMobile ? fileNames.IS_AI_VIDEO_AVAIL_SM : fileNames.IS_AI_VIDEO_AVAIL_LG }
            data-aut-id={ source === ITEM_SOURCE.BROWSE ? 'vidAvailableSRPCLAbrowse' : 'vidAvailableSRPCLAsearch' }
        />);

        if (!!olxAutosTags || showFeaturedTag || sellerMembershiptype) {
            // eslint-disable-line no-unused-vars
            statusClass = css.statusTags;
            status = (<label>
                { olxAutosTags }
                {showFeaturedTag && <ImageWrapper
                    fileName={ `${staticAsseturl}/${HIGHLIGHT_TAG}` }
                    className={ classNames(css.highlightTag, memberTagCss) }
                    alt={ FEATURED_TAG.ALT }
                />}
            </label>);
        }
        const isOlxMobbi = config.get('isOlxMobbi');

        const objInstallment = {
            amount: emiNumberFormat(monthly_installment)
        };
        const contentInstallment = <Translation id="installmentPerMonth" values={ objInstallment } />;

        return (
            <ReactObserver tag={ tag } data-aut-id="itemBox" data-aut-category-id={ this.props.item.category_id } onChange={ this.isVisible } className={ classNames(css[`${this.props.visualizationType}ItemCard`], this.props.className) }>
                <Link
                    to={ this.getLinkToProp() }
                    className={ statusClass }
                    onClick={ this.onClick } >
                    {isSpinViewAvailable && <SpinViewTag isFeaturedTagVisible={ showFeaturedTag || !!olxAutosTags } isGridorBigView={ visualizationType !== 'list' } />}
                    {image
                        && <ItemImage
                            image={ image }
                            friendsInCommon={ friendsInCommon }
                            defaultSize={ defaultSize }
                            alt={ title }
                            visualizationType={ visualizationType }
                            listingType={ listingType }
                            height={ imageHeight }
                            width={ imageWidth }
                            isCarImage={ item.category_id === config.get(CONFIG.OLX_AUTOS, CONFIG.OLX_AUTOS_CAR_CATEGORY) }
                            videoAvailableBadge={ showVideoBadgeBasedOnConfig(videoBadgeFeatureConfig, isVideoAvailable, isSearchPage && isMobile) ? videoAvailableBadgeSRP : null }
                        />
                    }
                    {
                        getListingDescription(item)
                    }
                    {
                        listingType === LISTING_TYPES.ITEMS && <div className={ css.resume }>
                            <div className={ classNames(css.tags) }>
                                {!isOlxMobbi && <MembershipTagV1
                                    type={ sellerMembershiptype }
                                    iconClassName={ classNames(css.memberIconClassName, memberTagCss) }
                                />}
                                {status && <div className={ css.status }>{status}</div>}
                            </div>
                            {price && <div className={ css.priceSection }>
                                <span className={ classNames(css.price, { [css.price_promotion]: has_promotion }) } data-aut-id="itemPrice">{netPrice}</span>
                                {has_promotion && <div className={ css.discountSection } data-aut-id="itemDiscountInfo">
                                    <span className={ css.priceBeforeDiscount } data-aut-id="itemPriceBeforeDiscount" >
                                        {shortPriceFormat(priceBeforeDiscount, 'IDR')}
                                    </span>
                                    <span className={ css.discountTotal } data-aut-id="itemDiscountTotal">{`-${shortPriceFormat(discountAmount)}`}</span>
                                </div>}
                            </div>
                            }
                            {/* OLX/Desktop/Homepage */}
                            {main_info && !enableCall && (
                                <div className={ css.wrapMoreInfo }>
                                    <span className={ css.details } data-aut-id="itemDetails">{main_info}</span>
                                    {(user_type === 'OLX_Autos' || user_type === 'Franchise' || user_type === 'Preferred' || userType === 'OLX_Autos' || userType === 'Franchise' || userType === 'Preferred') && (
                                        monthly_installment !== 0 && (
                                            <div className={ css.installment }>
                                                <span className={ isMobile ? css.titleInstallmentMobile : css.titleInstallmentDesktop } data-aut-id="itemInstallment">
                                                    {contentInstallment}
                                                </span>
                                                <span className={ !isMobile && css.tooltipInstallment }>
                                                    {isMobile ? (
                                                        <TooltipInstallment item={ item } size={ 'mobileLeft' } />
                                                    ) : (
                                                        <>
                                                            {itemCardType === 'feed' ? (
                                                                <TooltipInstallment item={ item } size={ 'small' } />
                                                            ) : (
                                                                <TooltipInstallment item={ item } size={ 'large' } />
                                                            )}
                                                        </>
                                                    )}
                                                </span>
                                            </div>
                                        )
                                    )}
                                </div>
                            )}
                            {title && <span className={ classNames(css.title, { [css.mainTitle]: !price && !main_info }) } data-aut-id="itemTitle">{title}</span>}
                            {!enableCall && <div className={ css.bottomCardInfo }>
                                {
                                    itemLocation && (
                                        <span className={ css.location } data-aut-id="item-location">
                                            {itemLocation}
                                        </span>)
                                }
                                {
                                    date && (
                                        <span className={ css.date }>{date}</span>
                                    )
                                }
                            </div>}
                            {showStatistics && !enableCall && (
                                <div className={ css.statistics }>
                                    {!isEmpty(favorites) && (
                                        <div className={ css.favorites }>
                                            <i className={ `${icons.panameraIcons} ${icons['icon-FavoriteOn']}` } />
                                            <span>{favorites.count}</span>
                                        </div>
                                    )}
                                    {views !== null && (
                                        <div className={ css.views }>
                                            <i className={ `${icons.panameraIcons} ${icons['icon-View']}` } />
                                            <span>{views}</span>
                                        </div>
                                    )}
                                </div>
                            )}
                            { enableCall && <CallButton phoneNumber={ this.phoneInListing.data?.number } item={ item } data-aut-id="itemCardCallBtn" />}
                            { showVideoBadgeBasedOnConfig(videoBadgeFeatureConfig, isVideoAvailable, isSearchPage && !isMobile) && videoAvailableBadgeSRP}
                        </div>
                    }
                </Link>
                {
                    this.showFavBtn
                        && listingType === LISTING_TYPES.ITEMS && isMyOwnAd
                        && <ItemFavouriteIcon item={ item }
                            userType={ userType }
                            className={ classNames(css.favIcon, { [css.favIconOlx]: this.theme === THEMES.OLX }, { [css.favIconOlxMob]: this.theme === THEMES.OLX && isMobile && source !== HOME }) }
                            btnClassName={ this.theme === THEMES.OLX ? css.favIconBtn : '' } />
                }
                { has_promotion && <ImageWrapper
                    fileName={ isMobile ? fileNames.DISCOUNT_ICON_SMALL : fileNames.DISCOUNT_ICON_MEDIUM }
                    types={ WEBP_SVG_TYPE }
                    addSVG
                    supportForDifferentSize
                    className={ css.discountIcon }
                    data-aut-id="iconDiscount"
                />}
                { showVideoBadgeBasedOnConfig(videoBadgeFeatureConfig, isVideoAvailable, !isSearchPage) && <ImageWrapper
                    addSVG
                    types={ WEBP_SVG_TYPE }
                    supportForDifferentSize
                    data-aut-id={ source === ITEM_SOURCE.FAVOURITES ? 'vidAvailableFavCLA' : 'vidAvailableHomeCLA' }
                    fileName={ isMobile ? fileNames.IS_AI_VIDEO_AVAIL_SM : fileNames.IS_AI_VIDEO_AVAIL_LG }
                    className={ (olxAutosTags && showFeaturedTag) ? css.aiVideoBadgeOnFullTag : css.aiVideoBadge }
                /> }
                {this.showInspectionTag() && <div
                    className={ classNames(css[`${this.props.visualizationType}ViewInspectedIcon`], {
                        [css[`${PLATFORM}FeedItemCard`]]: itemCardType === ITEM_CARD_TYPE.FEED
                    }) }
                    onClick={ this.handleTrustTagTap }
                >
                    {this.carInspectedIconUri && <ImageWrapper
                        fileName={ this.carInspectedIconUri }
                        fileSize={ X }
                        className={ css.carInspectedImage }
                        types={ WEBP_SVG_TYPE } />
                    }
                </div>
                }
            </ReactObserver>
        );
    }
}

export const mapStateToProps = (state, ownProps) => ({
    trackOrigin: state.track.origin,
    generalConfig: configSelector(state),
    selectFrom: (ownProps.location.state && ownProps.location.state.source),
    loggedUser: getLoggedUser(state),
    userType: userSelector(state, ownProps.item?.user_id)?.dealer?.car?.dealer_type || USER_TYPE.REGULAR,
    videoBadgeFeatureConfig: getAIGeneratedAdsVideoConfig(configSelector(state).features)
});

export default compose(
    withConfig,
    withTrack,
    withRouter,
    withBackButtonHandling(trustTagHash),
    connect(mapStateToProps, null)
)(ItemCard);
