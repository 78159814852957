/* global window */
import React from 'react';
import PropTypes from 'prop-types';
import { supportSessionStorage } from 'Helpers/sessionStorage';
export const PREFIX_KEY = 'scroll_';

export default class ScrollRestoration extends React.Component {
    static propTypes = {
        children: PropTypes.element.isRequired,
        id: PropTypes.string.isRequired
    }

    componentDidMount = () => {
        if (supportSessionStorage()) {
            const scrollY = parseInt(window.sessionStorage.getItem(this.getKey()), 10) || 0;

            window.scrollTo(0, scrollY);
        }
    }

    componentWillUnmount = () => {
        if (supportSessionStorage()) {
            Object.keys(window.sessionStorage)
                .filter(key => key && key.includes(PREFIX_KEY))
                .forEach(key => window.sessionStorage.removeItem(key));
            window.sessionStorage.setItem(this.getKey(), window.pageYOffset);
        }
    }

    getKey = () => `${PREFIX_KEY}${this.props.id}`;

    render() {
        return this.props.children;
    }
}
