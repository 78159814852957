import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { urls as locationsUrls } from 'Reducers/locations';
import { get } from '../../server';
import * as filtersHelper from 'Helpers/filters';
import * as locationsHelper from 'Helpers/locations';
import setSelectedLocationActionCreator, { resetLocation } from 'Actions/selectLocation';
import { getCountryLocation, setGPS, setLocationGps, requestGps, updateGPSStatus, getLocationsById } from 'Actions/locations';
import { getLocationPathById, getLocationPathByLatLon } from 'Actions/locationPath';
import { getNearByAds } from 'Actions/categoryBrowsing';
import {
    countryLocationSelector,
    isFetchingLocationByIdSelector,
    isFetchingLocationPathSelector,
    locationByLatLonSelector,
    locationByIdSelector,
    selectedLocationSelector,
    isFetchingGps,
    isGpsEnabledInDeviceSelector
} from 'Selectors/location';
import { categorySelector } from 'Selectors/items';
import { configSelector } from 'Selectors/config';
import withRouter from 'HOCs/withRouter';
import { withConfig } from 'HOCs/withConfig/withConfig';
import { CONFIG } from 'Constants/config';
import { COUNTRY } from 'Constants/locations';

const getLastDecendant = locationPath => {
    const withoutCountry = locationPath.filter(loc => !!loc?.parentId);

    if (withoutCountry.length > 0) {
        return withoutCountry.sort((a, b) => b.parentId - a.parentId)[0];
    }

    return locationPath[0];
};

const findLocationInPath = (lat, lon, id, locationPath = []) =>
    locationPath.find(loc =>
        (loc.id === Number(id) || (loc.latitude === lat && loc.longitude === lon))
    );

const withLocation = WrappedComponent => {
    class WithLocation extends React.Component {
        componentDidMount() {
            this.props.updateGPSStatus(); // Check If Location Is Enabled or Not

            const { lat, lon, id } = locationsHelper.getLocationPath(this.props) || {};

            if (id && !this.props.isFetchingGps) {
                return this.setLocationDataById(id, true);
            }
            if (!id && (lat && lon)) {
                return this.setLocationDataByLatLon(lat, lon, true);
            }
            if (!(id && lon && lat)) {
                return this.setLocationDataByDefault(true);
            }
            return null;
        }

        componentDidUpdate(prevProps) {
            const {
                lat: nextLat,
                lon: nextLon,
                id: nextId
            } = locationsHelper.getLocationPath(this.props) || {};
            const { lat, lon, id } = locationsHelper.getLocationPath(prevProps) || {};
            const hasLatLonChange = nextLat !== lat && nextLon !== lon;
            const hasIdChange = (nextId !== id) && (Number(nextId) !== Number(id));

            if (hasIdChange && nextId) {
                return this.setLocationDataById(nextId, true);
            }

            if (!hasIdChange && hasLatLonChange) {
                if ((!!nextLat && !!nextLon)) {
                    return this.setLocationDataByLatLon(nextLat, nextLon);
                }
            }

            return null;
        }

        persistLocationPath = locationPath => {
            const location = { ...locationPath[0] };

            if (location.addressComponents) {
                // deleting to reduce locationPath cookie size
                // this key is not used from cookie
                delete location.addressComponents;
            }

            locationsHelper.createLocationPathCookie([location]);
        }

        getLocationPath = locationObject => {
            const { addressComponents = [] } = locationObject;

            return addressComponents.map(({ id }) => this.props.locationById(id));
        };

        setLocationDataByLatLon = (lat, lon, shouldUpdateSelection = false) => {
            if (!this.props.locationByLatLon(lat, lon)) {
                return this.props.getLocationPathByLatLon({ lat, lon }).then(res => {
                    if (res) {
                        this.persistLocationPath([getLastDecendant(res.data.data)]);
                        const findLocation = findLocationInPath(lat, lon, undefined, res.data.data)
                            || getLastDecendant(res.data.data);

                        if (shouldUpdateSelection) {
                            this.props.setSelectedLocation(findLocation);
                        }
                    }
                });
            }
            const fetchedLocationByLatLon = this.props.locationByLatLon(lat, lon);

            if (shouldUpdateSelection) {
                this.props.setSelectedLocation(fetchedLocationByLatLon);
            }
            const locationPath = this.getLocationPath(fetchedLocationByLatLon);

            if (locationPath.length > 0) {
                this.persistLocationPath([getLastDecendant(locationPath)]);
            }
            return null;
        };

        setLocationDataById = (id, shouldUpdateSelection = false) => {
            const { marketConfig } = this.props;
            const enableDefaultCountry = marketConfig.get(CONFIG.DEFAULT_CITY, CONFIG.ENABLE_DEFAULT_COUNTRY);
            const fetchedLocationById = this.props.locationById(id);

            if (!fetchedLocationById || fetchedLocationById.type !== COUNTRY) {
                return this.props.getLocationPathById(id).then(res => {
                    if (res && res.data && res.data.data && res.data.data.length) {
                        const locations = res.data.data;

                        if (shouldUpdateSelection) {
                            this.props.setSelectedLocation(locations[0]);
                        }

                        const locationPath = this.getLocationPath(locations[0]);

                        if (locationPath.length > 0) {
                            this.persistLocationPath([getLastDecendant(locationPath)]);
                        }
                    }
                });
            }
            if (enableDefaultCountry && fetchedLocationById.type === COUNTRY) {
                this.persistLocationPath([fetchedLocationById]);
            }
            if (!enableDefaultCountry) {
                this.props.resetLocation();
            }

            if (!this.props.isFetchingGps) {
                locationsHelper.removeLocationPathCookie();
            }
            return null;
        };

        setLocationDataByDefault = (useLocationCookie = false) => {
            const { setSelectedLocation, params, categoryById, countryLocation, getNearByAds, setGPS, setLocationGps } = this.props;
            const locationCookie = locationsHelper.readLocationPathCookie();

            if (useLocationCookie && locationCookie) {
                const { geoID } = params;
                const attr = geoID ? 'id' : 'type';
                const compareTo = geoID || categoryById;
                const defaultLocationByType = locationCookie.find(loc => loc[attr] === compareTo);
                const defaultLocation = defaultLocationByType || getLastDecendant(locationCookie);

                if (defaultLocation.gps) {
                    return getNearByAds()
                        .then(location => {
                            if (location && location.gps) {
                                setLocationGps(location);
                                setGPS(location.gps);
                            }
                        })
                        .catch(() => this.props.resetLocation());
                }

                return setSelectedLocation(defaultLocation);
            }
            return setSelectedLocation(countryLocation);
        };

        handleOnLocationChange = (lat, lon, id, shouldUpdateSelection = false) => {
            if (lat && lon) {
                return this.setLocationDataByLatLon(lat, lon, true);
            }
            if (id) {
                return this.setLocationDataById(id, shouldUpdateSelection);
            }
            return null;
        };

        isFetchingGeoLocation = () => {
            const { lat, lon, id } = locationsHelper.getLocationPath(this.props) || {};
            const { isFetchingLocations, isFetchingLocationById } = this.props;

            return isFetchingLocations({ lat, lon })
                || isFetchingLocationById(id);
        };

        getLocationName = () => {
            const { selectedLocation } = this.props;

            if (selectedLocation && selectedLocation.name) {
                return selectedLocation.name;
            }
            return undefined;
        };

        render() {
            const { config, ...restProps } = this.props;
            const locationCountry = config?.location?.country.name;

            const geoLocationName = this.getLocationName();
            const isFetchingGeoLocation = this.isFetchingGeoLocation();

            return (
                <WrappedComponent
                    { ...restProps }
                    isFetchingGeoLocation={ isFetchingGeoLocation }
                    locationCountry={ locationCountry }
                    geoLocationName={ geoLocationName }
                    onLocationChange={ this.handleOnLocationChange }
                />
            );
        }
    }

    WithLocation.propTypes = {
        locationById: PropTypes.func,
        getLocationPathByLatLon: PropTypes.func,
        getLocationPathById: PropTypes.func,
        isFetchingLocations: PropTypes.func,
        isFetchingLocationById: PropTypes.func,
        config: PropTypes.shape({
            location: PropTypes.shape({
                country: PropTypes.shape({
                    name: PropTypes.string
                })
            }),
            defaultCity: PropTypes.object
        }).isRequired,
        marketConfig: PropTypes.object,
        selectedLocation: PropTypes.shape({
            id: PropTypes.number,
            latitude: PropTypes.number,
            longitude: PropTypes.number,
            type: PropTypes.string,
            name: PropTypes.string,
            addressComponents: PropTypes.array
        }),
        categoryById: PropTypes.object,
        countryLocation: PropTypes.shape({
            id: PropTypes.number,
            latitude: PropTypes.number,
            longitude: PropTypes.number,
            type: PropTypes.string
        }),
        setSelectedLocation: PropTypes.func,
        resetLocation: PropTypes.func.isRequired,
        getNearByAds: PropTypes.func,
        setGPS: PropTypes.func,
        setLocationGps: PropTypes.func,
        params: PropTypes.shape({
            categoryID: PropTypes.string,
            geoID: PropTypes.string
        }),
        locationByLatLon: PropTypes.func,
        isFetchingGps: PropTypes.bool.isRequired,
        updateGPSStatus: PropTypes.func
    };

    WithLocation.fetchData = (...args) => {
        const [dispatch, renderProps, reqProps, { config }, store] = args;
        const locationByCookie = reqProps && reqProps.cookies && reqProps.cookies.locationPath;
        const locationByFilter = renderProps.location.query && renderProps.location.query.location
            ? filtersHelper.decodeFilters(renderProps.location.query.location)
            : undefined;
        const locationByUri = renderProps.params.geoID ? { id: renderProps.params.geoID } : undefined;
        const hasLocation = locationByUri || locationByFilter;

        const promises = [
            (WrappedComponent.fetchData ? WrappedComponent.fetchData(...args) : Promise.resolve())
        ];

        if (hasLocation) {
            if (hasLocation.id) {
                promises.push(dispatch(
                    get(
                        `${reqProps.baseUrl}${locationsUrls.getLocationPathById(hasLocation.id)}`,
                        'LOCATIONS',
                        {},
                        reqProps.headers
                    )
                ));
            }
            else if (!hasLocation.id && hasLocation.lat) {
                promises.push(dispatch(
                    get(
                        `${reqProps.baseUrl}${locationsUrls.getLocationPath}`,
                        'LOCATIONS',
                        { lat: hasLocation.lat, lon: hasLocation.lon },
                        reqProps.headers
                    )
                ));
            }
            else {
                promises.push(Promise.resolve());
            }
        }

        if (!hasLocation && locationByCookie) {
            const cookieValue = JSON.parse(locationByCookie);
            const location = cookieValue && cookieValue.length && cookieValue[0];

            if (location && location.id) {
                promises.push(dispatch(getLocationPathById(location.id, reqProps.headers)));
            }
            else if (location && location.gps) {
                dispatch(requestGps());
            }
        }
        else {
            const locationId = config?.defaultCity?.enabled ? config?.defaultCity?.id : null;

            if (locationId) {
                promises.push(dispatch(getLocationsById(locationId, true, config?.defaultCity?.countryId, reqProps.headers)));
            }
            else {
                promises.push(dispatch(getCountryLocation({}, reqProps.baseUrl, reqProps.headers, config)));
            }
        }
        return Promise.all(promises).then(responses => {
            const locationData = (responses[1] && responses[1].data)
                ? responses[1].data.data
                : undefined;
            const state = store.getState();

            if (locationData) {
                if (hasLocation) {
                    const { id, lat, lon } = hasLocation || {};
                    const findLocation = findLocationInPath(lat, lon, id, locationData);

                    if (findLocation) {
                        return dispatch(setSelectedLocationActionCreator(findLocation));
                    }
                }
                if (locationByCookie) {
                    const { categories: { elements: categories = {}}} = state || {};
                    const { categoryID, geoID } = renderProps.params;
                    const defaultLocationLevel = categories
                        && categoryID
                        && categories[categoryID]
                        && categories[categoryID].default_location_level;
                    const attr = geoID ? 'id' : 'type';
                    const compareTo = geoID || defaultLocationLevel;
                    const defaultLocationByType = locationData.find(loc => loc[attr] === compareTo);
                    const defaultLocation = defaultLocationByType || getLastDecendant(locationData);

                    return dispatch(setSelectedLocationActionCreator(defaultLocation));
                }
            }

            return dispatch(setSelectedLocationActionCreator(countryLocationSelector(state)));
        });
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    WithLocation.displayName = `withLocation(${wrappedComponentName})`;

    const mapStateToProps = (state, props) => ({
        config: configSelector(state),
        locationById: locationByIdSelector(state),
        locationByLatLon: locationByLatLonSelector(state),
        isFetchingLocations: isFetchingLocationPathSelector(state),
        isFetchingLocationById: isFetchingLocationByIdSelector(state),
        categoryById: categorySelector(state, props),
        countryLocation: countryLocationSelector(state),
        selectedLocation: selectedLocationSelector(state),
        isFetchingGps: isFetchingGps(state),
        isGpsEnabled: isGpsEnabledInDeviceSelector(state)
    });

    const mapDispatchToProps = ({
        getLocationPathByLatLon,
        getLocationPathById,
        setSelectedLocation: setSelectedLocationActionCreator,
        getNearByAds,
        setGPS,
        setLocationGps,
        resetLocation,
        updateGPSStatus
    });

    return hoistNonReactStatic(
        compose(
            withConfig(CONFIG.MARKET_CONFIG),
            withRouter,
            connect(mapStateToProps, mapDispatchToProps)
        )(WithLocation),
        WrappedComponent,
        { fetchData: true }
    );
};

export default withLocation;
