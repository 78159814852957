import { INTEREST_ANNUALLY_DIVIDER } from 'Constants/financeConfig';

export const getEMIChoices = (loanAmount, interestRate, repaymentPeriodCounts) => {
    return (repaymentPeriodCounts || []).map(repaymentPeriodCount => {
        const monthlyInterestRate = interestRate / INTEREST_ANNUALLY_DIVIDER; // As defined by esteban@olxautos.com
        const multiplier = Math.pow((1 + monthlyInterestRate), repaymentPeriodCount);
        const f1 = loanAmount * monthlyInterestRate * multiplier;
        const f2 = multiplier - 1;

        return {
            repaymentPeriodCount,
            emi: Math.round(f1 / f2)
        };
    });
};

export const getMinLoanAmountLimit = (carValue, minLoanAmount, minLoanPercentage) => {
    if (carValue && minLoanAmount && minLoanPercentage) {
        const calculatedLoanAmount = carValue * minLoanPercentage;

        return Math.max(calculatedLoanAmount, minLoanAmount);
    }
    return 0;
};

export const getMaxLoanAmountLimit = (carValue, maxLoanAmount, maxLoanPercentage) => {
    if (carValue && maxLoanAmount && maxLoanPercentage) {
        const calculatedLoanAmount = carValue * maxLoanPercentage;

        return Math.min(calculatedLoanAmount, maxLoanAmount);
    }
    return 0;
};
