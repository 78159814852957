import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import { getLocationUrl } from 'Helpers/url';
import withRouter from 'HOCs/withRouter';

const withBackButtonHandling = (overlayHash, prevHash) => WrappedComponent => {
    class withBackButtonHandling extends React.PureComponent {
        static propTypes = {
            router: PropTypes.object,
            location: PropTypes.object
        };

        constructor(props) {
            super(props);
            this.overlayHash = overlayHash;
        }

        componentDidMount() {
            if (this.containsOverlayHash(this.overlayHash)) {
                this.openOverlay();
            }
        }

        containsOverlayHash = overlayHash => {
            return this.props.location.hash.includes(overlayHash);
        }

        openModal = () => {
            const locationUrl = getLocationUrl(this.props.location);
            let updatedUrl = locationUrl;

            if (!locationUrl.includes(this.overlayHash)) {
                updatedUrl = `${locationUrl}${this.overlayHash}`;
            }
            this.props.router.push(updatedUrl);
        }

        openOverlay = () => {
            const locationUrl = getLocationUrl(this.props.location);

            if (prevHash && !locationUrl.includes(overlayHash)) {
                if (locationUrl.includes(prevHash)) {
                    this.props.router.push(`${locationUrl}-${this.overlayHash}`);
                }
                else {
                    this.props.router.push(`${locationUrl}${prevHash}-${this.overlayHash}`);
                }
            }
            else if (!prevHash) {
                this.openModal();
            }
        }

        closeModal = () => {
            let locationUrl = getLocationUrl(this.props.location);

            if (this.containsOverlayHash(overlayHash)) {
                locationUrl = locationUrl.split('#')[0];
                this.props.router.replace(locationUrl);
            }
        }

        render() {
            const isOpened = this.containsOverlayHash(overlayHash);

            return (
                <WrappedComponent
                    { ...this.props }
                    openModal={ this.openModal }
                    openOverlay={ this.openOverlay }
                    closeModal={ this.closeModal }
                    containsOverlayHash={ this.containsOverlayHash }
                    isOpened={ isOpened }
                />
            );
        }
    }

    const connectedComponent = withRouter(withBackButtonHandling);

    connectedComponent.displayName = `withBackButtonHandling(${WrappedComponent.displayName || WrappedComponent.name})`;

    return hoistNonReactStatic(connectedComponent, WrappedComponent);
};

export default withBackButtonHandling;
