/* global window */
import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Button from 'Components/Button/Button';

import css from './BackToTop.scss';
import withTrack from 'HOCs/withTrack/withTrack';

const DEBOUNCE_TIME = 50;
const SPLIT_SCREEN_BY = 3;

export class BackToTop extends React.Component {
    static propTypes = {
        intl: PropTypes.shape({
            formatMessage: PropTypes.func.isRequired
        }).isRequired,
        track: PropTypes.func.isRequired,
        trackOrigin: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            display: false
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    shouldDisplay = () =>
        window.scrollY > (document.body.clientHeight / SPLIT_SCREEN_BY);

    handleScroll = debounce(() => {
        const shouldDisplay = this.shouldDisplay();
        const { display } = this.state;

        if (shouldDisplay) {
            return display ? null : this.setState({ display: true });
        }
        return display ? this.setState({ display: false }) : null;
    }, DEBOUNCE_TIME);

    handleOnClick = () => {
        this.setState({ display: false });
        window.scrollTo(0, 0);
        this.props.track('nav_back_to_top', { origin: this.props.trackOrigin });
    };

    render() {
        const { intl } = this.props;
        const { display } = this.state;

        if (!display) {
            return null;
        }

        return (
            <div className={ css.backtotop } data-aut-id="backToTop">
                <Button
                    type="secondaryBtn"
                    icon="arrowUp"
                    ref={ this.elemRef }
                    fixed={ true }
                    onClick={ this.handleOnClick }
                    size="small"
                    roundCorners
                    className={ css.btn }
                >
                    <span>
                        { intl.formatMessage({ id: 'backToTop', defaultMessage: 'Back to top' }) }
                    </span>
                </Button>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    trackOrigin: state.track.origin
});

export default compose(
    injectIntl,
    connect(mapStateToProps),
    withTrack
)(BackToTop);
