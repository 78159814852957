import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SearchAllSubCategoryRow from './SearchAllSubCategoryRow';
import css from './SearchAllCategories.scss';
import { Link } from 'panamera-react-ui';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import { fileNames } from 'Constants/imageUrls';
import { SVG_TYPE } from 'Constants/images';
import { BROWSE_CATEGORY_SELECT } from 'Constants/tracking';
import { paramsToQueryString, queryStringToParams } from 'Helpers/url';

const SuggestedCategories = ({ categories, onClick, location, config, track, trackOrigin = 'home', userId = '', userSessionLong = '' }) => {
    const showCarBrandInSuggestedCategories = config.get('showCarBrandInSuggestedCategories');
    const autosBadgeLink = config.get('autosBadgeLink') || '';
    const [baseUrl, queryString] = autosBadgeLink.split('?');

    const redirectionUserParams = {
        redirection_session_id: userSessionLong || '',
        redirection_userid: userId || ''
    };

    const utmParams = { ...queryStringToParams(queryString), ...redirectionUserParams };
    const url = `${baseUrl}?${paramsToQueryString(utmParams)}`;

    const handleClick = useCallback(() => {
        track(BROWSE_CATEGORY_SELECT, {
            origin: trackOrigin,
            select_from: 'category_carousel_olxautos'
        });
    }, [track, trackOrigin]);

    return (
        <div className={ css.suggestedCategories }>
            { showCarBrandInSuggestedCategories && <div className={ classnames(css.subCategory, css.cateory) } onClick={ handleClick }>
                <Link
                    to={ url }
                >
                    <ImageWrapper
                        fileName={ fileNames.OTOPLUS_SMALL_LOGO }
                        types={ SVG_TYPE }
                        className={ css.carbrandBadge }
                    />
                </Link>
            </div> }
            {
                categories.map(
                    category => (
                        category && <SearchAllSubCategoryRow
                            category={ category }
                            onClick={ onClick }
                            className={ css.category }
                            key={ `suggestedCategory_${category.id}` }
                            location={ location }
                        />
                    ))
            }
        </div>
    );
};

SuggestedCategories.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.string
        })
    ).isRequired,
    onClick: PropTypes.func.isRequired,
    location: PropTypes.object,
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired,
    track: PropTypes.func.isRequired,
    trackOrigin: PropTypes.string,
    userSessionLong: PropTypes.string,
    userId: PropTypes.string
};

export default SuggestedCategories;
