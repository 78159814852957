import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// COMPONENTS
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import withConfig from 'HOCs/withConfig/withConfig';

// CONSTANTS
import { CONFIG } from 'Constants/config';
import { PREMIUM_TAG, SUPER_DEALER_TAG } from 'Constants/imageUrls';
import { PREMIUM_USER_TYPE } from 'Constants/users';

// HELPERS
import { checkIfPremiumUser, checkIfSuperUser } from 'Helpers/premiumUser';

// CSS
import css from './MembershipTagV1.scss';

const MembershipTagV1 = ({
    type,
    config,
    containerClassName = '',
    iconClassName = '',
    showTagWithoutBackGround = false
}) => {
    const staticAsseturl = config.get(CONFIG.STATIC_ASSETS);

    if (checkIfPremiumUser(type)) {
        return (<div className={ cx(css.tagContainer, css.backgroundPremium, {
            [css.showTagWithoutBackGround]: showTagWithoutBackGround
        }, containerClassName) }
        data-aut-id={ PREMIUM_USER_TYPE.PREMIUM }>
            <ImageWrapper
                fileName={ `${staticAsseturl}/${PREMIUM_TAG}` }
                className={ cx(css.premiumImage, iconClassName) }
                alt={ PREMIUM_USER_TYPE.PREMIUM }
            />
        </div>);
    }
    else if (checkIfSuperUser(type)) {
        return (<div className={ cx(css.tagContainer, css.backgroundSuperDealer, containerClassName) } data-aut-id={ PREMIUM_USER_TYPE.SUPER_USER }>
            <ImageWrapper
                fileName={ `${staticAsseturl}/${SUPER_DEALER_TAG}` }
                className={ cx(css.premiumImage, iconClassName) }
                alt={ PREMIUM_USER_TYPE.SUPER_USER }
            />
        </div>);
    }
    return null;
};

MembershipTagV1.propTypes = {
    config: PropTypes.object,
    type: PropTypes.string,
    containerClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    showTagWithoutBackGround: PropTypes.string
};

export default compose(
    withConfig
)(MembershipTagV1);

