import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import NewIconButton from 'Components/ThematicNewIconButton/ThematicNewIconButton';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Auth from '../../Auth';
import { addUserFavourite, removeUserFavourite } from 'Actions/user';
import { favouriteItemsSelector } from 'Selectors/user';
import { getFavouriteItemInfo, setSelectFrom } from 'Helpers/track';
import setLoginModalState from 'Actions/setLoginModalState';
import withTrack from 'HOCs/withTrack/withTrack';
import { SOCIAL_TAP_LIKE, SOCIAL_TAP_UNLIKE } from 'Constants/tracking';
import { ICONS, FAVOURITE } from 'Constants/items';
import { setFlowStep } from 'Actions/track';
import { TrackerOrigins } from 'Components/TrackerProvider/TrackerProvider';

export const ItemFavouriteIcon = ({ item, isFavourite, addFavourite, removeFavourite, _setLoginModalState, track, trackOrigin, className, btnClassName, size, color, browsingMode, selectFrom, setFlowStepTrack }) => {
    const onClick = useCallback(() => {
        const user = Auth.getUser();

        setFlowStepTrack(selectFrom || setSelectFrom(browsingMode));

        if (user) {
            const trackInfo = {
                origin: trackOrigin,
                select_from: selectFrom || setSelectFrom(browsingMode),
                ...getFavouriteItemInfo(item)
            };

            if (isFavourite) {
                track(SOCIAL_TAP_UNLIKE, trackInfo);
                removeFavourite(user.id);
            }
            else {
                track(SOCIAL_TAP_LIKE, trackInfo);
                addFavourite(user.id);
            }
        }
        else {
            _setLoginModalState({ state: true, redirectTo: '', trackProps: { origin: TrackerOrigins.FAVOURITE }, entryPoint: 2, callback: onClick });
        }
    }, [setFlowStepTrack, trackOrigin, selectFrom, browsingMode, item, isFavourite, track, removeFavourite, addFavourite, _setLoginModalState]);

    const icon = isFavourite ? ICONS.FAV_ON : ICONS.FAV_OFF;

    return (
        <span className={ className }>
            <NewIconButton
                name={ FAVOURITE }
                icon={ icon }
                color={ color }
                animation={ false }
                onClick={ onClick }
                className={ classNames(btnClassName, icon) }
                size={ size }
                data-aut-id="btnFav"
            />
        </span>
    );
};

const mapStateToProps = (state, { item }) => ({
    isFavourite: favouriteItemsSelector(state, item.ad_id || item.id),
    trackOrigin: state.track.origin,
    browsingMode: state.track.browsingMode
});

export const mapDispatchToProps = (dispatch, { item, userType }) => ({
    addFavourite: userId => dispatch(addUserFavourite(userId, parseInt(item.ad_id || item.id, 10), item.category_id, item.user_type || userType)),
    removeFavourite: userId => dispatch(removeUserFavourite(userId, parseInt(item.ad_id || item.id, 10), item.category_id, item.user_type || userType)),
    _setLoginModalState: state => dispatch(setLoginModalState(state)),
    setFlowStepTrack: flowStep => dispatch(setFlowStep(flowStep))
});

ItemFavouriteIcon.propTypes = {
    isFavourite: PropTypes.bool.isRequired,
    addFavourite: PropTypes.func.isRequired,
    removeFavourite: PropTypes.func.isRequired,
    _setLoginModalState: PropTypes.func.isRequired,
    track: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    trackOrigin: PropTypes.string,
    browsingMode: PropTypes.string,
    className: PropTypes.string,
    btnClassName: PropTypes.string,
    size: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    color: PropTypes.string,
    selectFrom: PropTypes.string,
    setFlowStepTrack: PropTypes.func
};

ItemFavouriteIcon.defaultProps = {
    trackOrigin: '',
    className: '',
    btnClassName: '',
    color: ''
};

export default compose(
    withTrack,
    connect(mapStateToProps, mapDispatchToProps)
)(ItemFavouriteIcon);
