import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { normalizeString } from 'Helpers/seo';
import { withConfig } from '../../hocs/withConfig/withConfig';

export const SEO = ({
    seoConfig,
    title,
    description,
    carsPage,
    page,
    tagName,
    ogTag,
    defaultTitle
}) => {
    const config = seoConfig && seoConfig.get('SEO');
    const titleTemplate = config && config.titleTemplate || '';
    const defaultTitleProp = config && config.defaultTitle || '';
    const defaultDescription = config && config.defaultDescription || '';

    const props = {
        titleTemplate: `${carsPage || page === 'listing' ? '' : titleTemplate }`,
        defaultTitle: defaultTitle || defaultTitleProp,
        meta: [
            {
                ...(ogTag ? { property: tagName } : { name: tagName }),
                content: description?.length ? normalizeString(description) : defaultDescription
            }
        ]
    };

    if (title.length) {
        props.title = normalizeString(title);
    }

    return (
        <Helmet { ...props } />
    );
};

SEO.propTypes = {
    title: PropTypes.string,
    defaultTitle: PropTypes.string,
    seoConfig: PropTypes.shape({
        get: PropTypes.func.isRequired
    }),
    description: PropTypes.string,
    carsPage: PropTypes.bool,
    page: PropTypes.string,
    tagName: PropTypes.string,
    ogTag: PropTypes.bool
};

SEO.defaultProps = {
    title: '',
    titleTemplate: '',
    defaultTitle: '',
    description: '',
    defaultDescription: '',
    carsPage: false,
    page: '',
    tagName: 'description',
    ogTag: false
};

export default withConfig('seoConfig')(SEO);
