/* eslint-disable react-perf/jsx-no-jsx-as-prop */
import React, { Suspense } from 'react';

const VasSheet = React.lazy(() => import(
    /* webpackChunkName: "vas-sheet" */
    './VasSheet.APP_TARGET'
));

const FallBackComponent = () => null;

const VasSheetAsync = props => {
    if (typeof window === 'undefined') {
        return null;
    }

    return (
        <Suspense fallback={ <FallBackComponent /> } >
            <VasSheet { ...props } />
        </Suspense>
    );
};

export default VasSheetAsync;
