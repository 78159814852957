import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as Translation } from 'react-intl';
import css from './TooltipInstallment.scss';
import Icon from 'Components/ThematicIcon/ThematicIcon';

export const TooltipInstallment = props => {
    const { size, item } = props;

    const monthly_installment = item && item.price && item.price.value && item.price.value.emi && item.price.value.emi.monthly_installment && item.price.value.emi.monthly_installment.display ? item.price.value.emi.monthly_installment.display : '0';
    const tenure = item && item.price && item.price.value && item.price.value.emi && item.price.value.emi.tenure ? item.price.value.emi.tenure : 0;
    const down_payment = item && item.price && item.price.value && item.price.value.emi && item.price.value.emi.down_payment && item.price.value.emi.down_payment.display ? item.price.value.emi.down_payment.display : '0';

    const objMonthlyTenur = {
        amount: monthly_installment,
        tenure
    };

    const translations = {
        installment: <Translation id="installment" />,
        downPayment: <Translation id="downPayment" />,
        estimatedCredit: <Translation id="estimatedCredit" />,
        installmentTenure: <Translation id="installmentTenure" values={ objMonthlyTenur } />
    };

    const handleTooltipHover = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleClassNameTooltip = value => {
        switch (value) {
            case 'mobile':
                return css.tooltipMobile;
            case 'mobileLeft':
                return css.tooltipMobile;
            case 'small':
                return css.tooltip;
            case 'large':
                return css.tooltip;
            default:
                return null;
        }
    };

    const handleClassNameTooltipText = value => {
        switch (value) {
            case 'small':
                return css.tooltiptextSmall;
            case 'large':
                return css.tooltiptextLarge;
            case 'mobile':
                return css.tooltiptextMobile;
            case 'mobileLeft':
                return css.tooltiptextMobileLeft;
            default:
                return null;
        }
    };

    const handleClassNameTitle = value => {
        switch (value) {
            case 'small':
                return css.titleSmall;
            case 'large':
                return css.titleLarge;
            case 'mobile':
                return css.titleMobile;
            case 'mobileLeft':
                return css.titleMobile;
            default:
                return null;
        }
    };

    const handleClassNameLabel = value => {
        switch (value) {
            case 'small':
                return css.labelSmall;
            case 'large':
                return css.labelLarge;
            case 'mobile':
                return css.labelMobile;
            case 'mobileLeft':
                return css.labelMobile;
            default:
                return null;
        }
    };

    const handleClassNameContent = value => {
        switch (value) {
            case 'small':
                return css.contentSmall;
            case 'large':
                return css.contentLarge;
            case 'mobile':
                return css.contentMobile;
            case 'mobileLeft':
                return css.contentMobile;
            default:
                return null;
        }
    };

    return (
        <span className={ handleClassNameTooltip(size) } onClick={ handleTooltipHover }>
            <Icon icon="info" size={ 16 } color="blueBase" />
            <div className={ handleClassNameTooltipText(size) }>
                <div className={ css.cardAdp }>
                    <div className={ handleClassNameTitle(size) }>
                        {translations.estimatedCredit}
                    </div>
                    <div className={ css.wrapper }>
                        <div className={ handleClassNameLabel(size) }>
                            {translations.downPayment}
                        </div>
                        <div className={ handleClassNameContent(size) } data-aut-id="itemAdp">
                            {down_payment}
                        </div>
                    </div>
                    <div className={ css.wrapper }>
                        <div className={ handleClassNameLabel(size) }>
                            {translations.installment}
                        </div>
                        <div className={ handleClassNameContent(size) } data-aut-id="itemAdp">
                            {translations.installmentTenure}
                        </div>
                    </div>
                </div>
            </div>
        </span>
    );
};

TooltipInstallment.propTypes = {
    size: PropTypes.string,
    item: PropTypes.object
};

export default (TooltipInstallment);
