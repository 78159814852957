/* eslint-disable no-magic-numbers */
export const displayPriceWithComma = price => {
    const roundedDiscount = Math.floor(price * 10) / 10;

    return roundedDiscount;
};

export const shortPriceFormat = (priceValue, currency = '') => {
    const priceCategory = Math.ceil((priceValue.toString().length) / 3);
    let prefix = '';

    switch (currency) {
        case 'IDR':
            prefix = 'Rp';
            break;
        case 'USD':
            prefix = '$';
            break;
        // Add other currencies as needed
        default:
            prefix = '';
    }

    switch (priceCategory) {
        case 2:
            return `${prefix}${displayPriceWithComma(priceValue / 1e3)}rb`;
        case 3:
            return `${prefix}${displayPriceWithComma(priceValue / 1e6)}jt`;
        case 4:
            return `${prefix}${displayPriceWithComma(priceValue / 1e9)}mil`;
        default:
            return `${prefix}${priceValue}`;
    }
};
