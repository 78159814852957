import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import hoistNonReactStatic from 'hoist-non-react-statics';
import withRouter from 'HOCs/withRouter';
import isEqual from 'lodash/isEqual';

import { withConfig } from 'HOCs/withConfig/withConfig';
import { getPopularSearches } from 'Selectors/seo';
import { getPopularSearches as fetchPopularSearches } from 'Actions/seoPopularSearches';

/**
 * Sets popular searches as props to child, based on url params and location urls, filters
 * @param {*} WrappedComponent
 */
export const withSearches = WrappedComponent => {
    class WithSearches extends Component {
        componentDidMount() {
            this.props.getPopularSearches();
        }

        componentDidUpdate(prevProps) {
            const { params, location } = this.props;

            if (!isEqual(params, prevProps.params) || !isEqual(location, prevProps.location)) {
                this.props.getPopularSearches();
            }
        }

        render() {
            const { enabled, searches, location, ...extraProps } = this.props;
            const props = {
                location,
                searches: enabled ? searches : [],
                'data-aut-id': 'withSearchesComponent',
                ...extraProps
            };

            return (
                <WrappedComponent
                    { ...props }
                />
            );
        }
    }

    WithSearches.propTypes = {
        params: PropTypes.object,
        location: PropTypes.object,
        enabled: PropTypes.bool,
        searches: PropTypes.array,
        getPopularSearches: PropTypes.func.isRequired
    };

    WithSearches.defaultProps = {
        enabled: false,
        searches: []
    };

    WithSearches.fetchData = (...args) => {
        const [dispatch, renderProps, reqProps, reqContext, store] = args; // eslint-disable-line no-unused-vars
        const { params, location } = renderProps;
        const { config } = reqContext;
        const promises = [(WrappedComponent.fetchData ? WrappedComponent.fetchData(...args) : Promise.resolve())];

        promises.push(fetchPopularSearches(config, params, location)(dispatch, store.getState));
        return Promise.all(promises);
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    WithSearches.displayName = `withSearches(${wrappedComponentName})`;

    const mapStateToProps = (state, { marketConfig: config, location, params }) => {
        return {
            enabled: !!config.get('SEO'),
            searches: getPopularSearches(state, { config, location, params })
        };
    };

    const mapDispatchToProps = (dispatch, { marketConfig: config, params, location }) => ({
        getPopularSearches: () => dispatch(fetchPopularSearches(config, params, location))
    });

    return hoistNonReactStatic(
        compose(
            withConfig('marketConfig'),
            withRouter,
            connect(mapStateToProps, mapDispatchToProps)
        )(WithSearches),
        WrappedComponent,
        { fetchData: true }
    );
};

export default withSearches;
