import React from 'react';
import PropTypes from 'prop-types';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import { SPIN_360_ICON } from 'Constants/imageUrls';
import { SVG_TYPE } from 'Constants/images';
import { IS_DESKTOP } from 'Constants/device.APP_TARGET';
import css from './SpinViewTag.scss';

const SpinViewTag = ({ isFeaturedTagVisible, isGridorBigView }) => {
    return (
        <ImageWrapper
            fileName={ SPIN_360_ICON }
            types={ SVG_TYPE }
            className={ isFeaturedTagVisible && isGridorBigView ? css.spinViewIconWithFeatured : css.spinViewIcon }
            height={ IS_DESKTOP ? '32' : '28' }
            width={ IS_DESKTOP ? '36' : '32' }
        />
    );
};

SpinViewTag.propTypes = {
    isFeaturedTagVisible: PropTypes.bool,
    isGridorBigView: PropTypes.bool
};

export default SpinViewTag;
