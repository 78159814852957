import { getLocalStorageItem, setLocalStorageItem } from './localStorage';
import { AI_VIDEO_ADS_CAMPAIGN_ID, AI_VIDEO_ADS_FEAT_STATUS, AI_VIDEO_ADS_GENERAL_CONFIG_NAME, AI_VIDEO_ADS_STORAGE, AI_VIDEO_ADS_VARIANT } from 'Constants/constants';

export const NULL_VARIANT = 'NULL-VARIANT';
/**
 * @typedef {Object} Feature
 * @property {Object} data - The data object containing feature details.
 * @property {string} data.status - The status of the feature.
 * @property {boolean} enabled - Indicates if the feature is enabled.
 * @property {string} name - The name of the feature.
 * @property {Object} params - Additional parameters for the feature.
 */

/**
 * Get the AI Generated Ads Video configuration from the features array.
 *
 * @param {Feature[]} features - The array of feature objects.
 * @returns {Feature|null} The AI Generated Ads Video configuration or null if not found.
 */
export const getAIGeneratedAdsVideoConfig = features => {
    try {
        if (!Array.isArray(features)) {
            return null;
        }

        const feature = features.find(feature => feature.name === AI_VIDEO_ADS_GENERAL_CONFIG_NAME);

        if (!feature) {
            return null;
        }

        return feature;
    }
    catch (error) {
        return null;
    }
};

/**
 * Determines whether to show the video badge based on the configuration.
 *
 * @param {Feature} config - The array of feature objects.
 * @param {boolean} isVideoAvailable - Indicates if the video is available.
 * @param {boolean} additionalConditions - Additional conditions to check.
 * @returns {boolean} True if the video badge should be shown, false otherwise.
 */
export const showVideoBadgeBasedOnConfig = (config, isVideoAvailable, additionalConditions) => {
    if (!config || !config.enabled) {
        return false;
    }

    return isVideoAvailable && additionalConditions;
};

/**
 * Determines the test variant based on the configuration.
 *
 * @param {boolean} isAttributeSatisfied - Indicates if the attribute condition is satisfied.
 * @param {Function} getVariant - A function to get the variant of the feature.
 * @param {boolean} isMobile - Indicates if the device is mobile.
 * @returns {string|null} AI-ON if the video player should be shown, AI-OFF or null otherwise.
 */
export const getTestVariantBasedOnConfig = (isAttributeSatisfied, getVariant, isMobile) => {
    if (!isAttributeSatisfied) {
        return null;
    }

    const existingVariant = getLocalStorageItem(AI_VIDEO_ADS_STORAGE);

    if (existingVariant) {
        return existingVariant;
    }

    const { DESKTOP, MOBILE } = AI_VIDEO_ADS_CAMPAIGN_ID;
    const variant = getVariant(isMobile ? MOBILE : DESKTOP);
    const resolvedVariant = variant === null ? NULL_VARIANT : variant;

    setLocalStorageItem(AI_VIDEO_ADS_STORAGE, resolvedVariant);

    return resolvedVariant;
};

/**
 * Determines whether to show the video player based on the configuration.
 *
 * @param {Feature} config - The feature configuration object.
 * @param {boolean} isVideoAvailable - Indicates if the video is available.
 * @param {Function} getVariant - A function to get the variant of the feature.
 * @param {boolean} isMobile - Indicates if the device is mobile.
 * @returns {string|null} AI-ON if the video player should be shown, AI-OFF or null otherwise.
 */
export const showVideoPlayerBasedOnConfig = (config, isVideoAvailable, getVariant, isMobile) => {
    if (!config) {
        return null;
    }

    const isFeatEnabled = config.enabled;
    const isConcluded = config.data.status === AI_VIDEO_ADS_FEAT_STATUS.CONCLUDED;
    const isInTestPhase = config.data.status === AI_VIDEO_ADS_FEAT_STATUS.IN_TESTING;

    if (isConcluded) {
        return isFeatEnabled && isVideoAvailable ? AI_VIDEO_ADS_VARIANT.AI_ON : null;
    }

    if (isInTestPhase) {
        return getTestVariantBasedOnConfig(isFeatEnabled && isVideoAvailable, getVariant, isMobile);
    }

    return null;
};

