/* global */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './Page.scss';
import Footer from 'Components/Footer/Footer';
import { scrollToTop } from 'Helpers/page';
import ScrollRestoration from 'Components/ScrollRestoration/ScrollRestoration';
import SubHeader from 'Components/SubHeader/SubHeader.APP_TARGET';
import { BLACKBACKGROUND2, BACKGROUND2, WHITE_TEXT } from 'Constants/colors';

const defaultFooter = <Footer />;
const defaultSubHeader = <SubHeader />;

export class Page extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.node,
        header: PropTypes.node,
        olxAutosHeader: PropTypes.node,
        scrollToTop: PropTypes.bool,
        footer: PropTypes.node,
        scrollId: PropTypes.string,
        subHeader: PropTypes.node,
        fullWidth: PropTypes.bool,
        offline: PropTypes.bool,
        pageBgColor: PropTypes.oneOf([BLACKBACKGROUND2, BACKGROUND2, WHITE_TEXT])
    }

    static defaultProps = {
        scrollToTop: false,
        footer: defaultFooter,
        scrollId: '',
        subHeader: defaultSubHeader,
        fullWidth: false,
        olxAutosHeader: null,
        offline: false,
        pageBgColor: WHITE_TEXT,
        className: ''
    }

    componentDidMount() {
        if (this.props.scrollToTop) {
            scrollToTop();
        }
    }

    render() {
        const { header, children, footer, scrollId, subHeader, fullWidth, offline, olxAutosHeader, pageBgColor, className } = this.props;
        const content = (
            <React.Fragment>
                { header }
                { subHeader }
                {olxAutosHeader}
                <main id="main_content" className={ classNames(css.page, { [css.offline]: offline }, css[pageBgColor], className) } data-aut-id="page-main-content">
                    <div className={ classNames(css.container, { [css.containerCentered]: !fullWidth }) }>
                        { children }
                    </div>
                </main>
                { footer }
            </React.Fragment>
        );

        return scrollId ? (
            <ScrollRestoration id={ scrollId }>
                { content }
            </ScrollRestoration>
        ) : content;
    }
}

export default Page;
