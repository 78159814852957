import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import Listing from './Listing';

const Meta = ({ module, title, description }) => {
    if (!title || !description) {
        switch (module) {
            case 'listing':
                return <Listing />;
            default:
                // XXX: default meta & title fallback
                break;
        }
    }

    return (
        <Helmet>
            <title>{ title }</title>
            <meta name="description" content={ description } />
        </Helmet>
    );
};

Meta.propTypes = {
    module: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string
};

export default Meta;
