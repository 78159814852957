import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withRouter from 'HOCs/withRouter';
import { FormattedMessage as Translation } from 'react-intl';
import { camelize, decodeSearch } from 'Helpers/strings';
import { getListingTitleDescriptionTranslationId } from 'Helpers/seo';
import withConfig from 'HOCs/withConfig/withConfig';
import SEO from '../SEO';
import { configSelector } from 'Selectors/config';

export const Default = ({ params: { geoSlug = '', text = '' }, category, country, brand }) => {
    const values = {
        querySearch: camelize(decodeSearch(text)),
        category: category && category.name,
        location: camelize(geoSlug),
        country,
        brand
    };
    const { titleTranslationId, descriptionTranslationId } = getListingTitleDescriptionTranslationId(values);

    return (
        <Translation id={ titleTranslationId } values={ values } >
            { formattedTitle => (
                <Translation id={ descriptionTranslationId } values={ values } >
                    { formattedDescription => (
                        <SEO
                            page="listing"
                            title={ formattedTitle }
                            description={ formattedDescription }
                        />
                    ) }
                </Translation>
            ) }
        </Translation>
    );
};

Default.propTypes = {
    params: PropTypes.shape({
        geoSlug: PropTypes.string,
        text: PropTypes.string,
        categoryID: PropTypes.string
    }).isRequired,
    category: PropTypes.object,
    country: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired
};

const mapStateToProps = (state, { config, params: { categoryID }}) => {
    const c = configSelector(state);

    return {
        country: c.location && c.location.country.name,
        brand: config.get('theme', 'brand'),
        category: state.categories.elements[categoryID]
    };
};

export default compose(
    withConfig,
    withRouter,
    connect(mapStateToProps)
)(Default);
