import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage as Translation } from 'react-intl';
import withRouter from 'HOCs/withRouter';
import withConfig from 'HOCs/withConfig/withConfig';
import { itemsMetadataSelector } from 'Selectors/items';
import { configSelector } from 'Selectors/config';
import SEO from '../SEO';

export const Cars = ({ appliedFilters, location, category, country, brand }) => {
    const titleTranslationValues = {};
    const descriptionTranslationValues = {};
    const makeFilter = appliedFilters && appliedFilters.find(appliedFilter => appliedFilter.id === 'make');
    const modelFilter = appliedFilters && appliedFilters.find(appliedFilter => appliedFilter.id === 'model');
    let titleTranslationID = 'titleForCarsCategoryListing';
    let descriptionTranslationID = 'metaDescriptionForCarsCategoryListing';

    titleTranslationValues.localization = location.name;
    titleTranslationValues.category = category && category.name || '';
    titleTranslationValues.brand = brand;
    titleTranslationValues.make = makeFilter && makeFilter.value;
    titleTranslationValues.model = modelFilter && modelFilter.value;
    titleTranslationValues.country = country;
    descriptionTranslationValues.localization = location.name;
    descriptionTranslationValues.category = category && category.name;
    descriptionTranslationValues.brand = brand;
    descriptionTranslationValues.make = makeFilter && makeFilter.value;
    descriptionTranslationValues.model = modelFilter && modelFilter.value;
    descriptionTranslationValues.country = country;

    if (location && location.name && category) {
        titleTranslationID = 'titleForCarsLocationCategoryListing';
        descriptionTranslationID = 'metaDescriptionForCarsLocationCategoryListing';

        if (makeFilter && makeFilter.value) {
            titleTranslationID = (modelFilter && modelFilter.value) ? 'titleForCarsMakeModelLocationCategoryListing' : 'titleForCarsMakeLocationCategoryListing';
            descriptionTranslationID = (modelFilter && modelFilter.value) ? 'metaDescriptionForCarsMakeModelLocationCategoryListing' : 'metaDescriptionForCarsMakeLocationCategoryListing';
        }
    }
    else if (makeFilter && makeFilter.value) {
        titleTranslationID = (modelFilter && modelFilter.value) ? 'titleForCarsMakeModelCategoryListing' : 'titleForCarsMakeCategoryListing';
        descriptionTranslationID = (modelFilter && modelFilter.value) ? 'metaDescriptionForCarsMakeModelCategoryListing' : 'metaDescriptionForCarsMakeCategoryListing';
    }

    return (
        <Translation id={ titleTranslationID } values={ titleTranslationValues }>
            {formattedTitle => (
                <Translation id={ descriptionTranslationID } values={ descriptionTranslationValues }>
                    {formattedDescription => (
                        <SEO
                            page="listing"
                            title={ formattedTitle }
                            description={ formattedDescription }
                            carsPage={ true }
                        />
                    )}
                </Translation>
            )}
        </Translation>
    );
};

Cars.propTypes = {
    appliedFilters: PropTypes.array,
    location: PropTypes.object.isRequired,
    category: PropTypes.object,
    country: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired
};

const mapStateToProps = (state, { config, params }) => {
    const c = configSelector(state);

    return {
        brand: config.get('theme', 'brand'),
        country: c.location && c.location.country.name,
        appliedFilters: (itemsMetadataSelector(state) || {}).appliedFilters,
        category: state.categories.elements[params.categoryID]
    };
};

export default compose(
    withConfig,
    withRouter,
    connect(mapStateToProps)
)(Cars);
