import { get } from '../server';
import { urls, TYPE } from '../reducers/seo';
import { paramsToQueryString } from '../../helpers/url';
import { getSEOLang } from 'Selectors/config';
import { getSeoTagsURL } from 'Helpers/seo';
import { getType } from 'Reducers/helpers/typeModifier';

const getParams = (config, params, lang = '') => ({
    siteCode: config.get('siteCode'),
    lang: lang || config.get('SEO', 'lang') || config.get('lang'),
    ...(params || {})
});

const getTag = (state, url, params, key = 'elements') => {
    const reducerType = getType(TYPE, `${url}?${paramsToQueryString(params)}`);

    return state.seo[key][reducerType];
};

export const mapStateToTags = (config, state, module, query) => {
    const lang = getSEOLang(state);
    const url = getSeoTagsURL(config, urls, module);

    return getTag(state, url, getParams(config, query, lang), 'elements');
};

export const getTags = (config, module, params, reqProps) => (dispatch, getState) => {
    const type = `ELEMENT_${TYPE}`;
    const state = getState();
    const lang = getSEOLang(state);
    const url = getSeoTagsURL(config, urls, module);

    params = getParams(config, params, lang);

    if (
        getTag(state, url, params, 'isFetching')
        || getTag(state, url, params, 'isError')
        || getTag(state, url, params)
    ) {
        return Promise.resolve();
    }

    if (reqProps) {
        return dispatch(get(`${reqProps.baseUrl}${url}`, type, params, reqProps.headers));
    }

    return dispatch(get(url, type, params));
};
