export const LAZYLOAD_OFFSET_VERTICAL = 600;
export const CONFIG = {
    MIDDLE_AD_HOME: 'middleAdHome',
    MIDDLE_AD_CATEGORY: 'middleAdCategory',
    ITEM_POST_PLACEHOLDER: 'itemPostPlaceholder',
    LAMUDI_BRANDING: 'lamudiBranding',
    MIDDLE_AD_PATTERN2: 'middleAdPattern2',
    CLICKABLE_IMAGE: 'clickableImage',
    BANNER: 'banner'
};
export const FEATURED_TAG = {
    IMAGE: '/external/base/img/featured.png',
    ALT: 'Featured'
};
