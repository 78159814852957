import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import SearchAllSubCategoryRow from './SearchAllSubCategoryRow';

const SearchAllSubCategories = ({ categories, parentId: parent_id, onClick, className }) => {
    const onSubCategoryClick = useCallback(subCategory => {
        onClick({
            ...subCategory,
            parent_id
        });
    }, [parent_id, onClick]);

    return (<React.Fragment>
        {categories.map(category => (<SearchAllSubCategoryRow
            category={ category }
            onClick={ onSubCategoryClick }
            key={ `categoryRow_${category.id}` }
            className={ className }
        />))
        }
    </React.Fragment>);
};

SearchAllSubCategories.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
        })
    ).isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default SearchAllSubCategories;

