import { isEmpty } from 'Helpers/objects';
import { TYPE, ADD_POPULAR_RANGE_USED, ADD_FILTERS_RECENCY_ORDER, CLEAR_FILTERS_RECENCY_ORDER } from 'Reducers/filters';
import { getSelectedLangCode, getSiteCode } from 'Selectors/config';
import { PLATFORM as APP_TARGET } from 'Constants/device.APP_TARGET';
import { DEFAULT_CATEGORY } from 'Constants/categories';
import { getType } from 'Reducers/helpers/typeModifier';
import SITE_CODES from 'Constants/siteCodes';
import { configSelector } from 'Selectors/config';

export const PLATFORM = `web-${APP_TARGET}`;
export const BXP_REQUIRED_HEADER = {
    'x-panamera-client-id': 'listings'
};

export const url = '/api/bxp/v3/listing-configurations/search';
export const getUrlWithParams = (categoryId, lang, variantInfo) => {
    let urlWithParams = lang ? `${url}?platform=${PLATFORM}&categoryId=${categoryId}&lang=${lang}` : `${url}?platform=${PLATFORM}&categoryId=${categoryId}`;

    if (variantInfo && !variantInfo.disabled) {
        urlWithParams += `&variant=${variantInfo.variant}`;
    }

    return urlWithParams;
};

const getBooleanFlags = (state, categoryId, variantInfo, booleanFlag) => {
    const urlWithParams = getUrlWithParams(categoryId, getSelectedLangCode(state), variantInfo);
    const type = getType(TYPE, urlWithParams);

    return state.filters[booleanFlag][type];
};

export const isError = (state, categoryId, variantInfo) => getBooleanFlags(state, categoryId, variantInfo, 'isError');
export const isFetching = (state, categoryId, variantInfo) => getBooleanFlags(state, categoryId, variantInfo, 'isFetching');

export const fetchFiltersByCategoryId = (isAutosPlatform, catId, variantInfo) => (dispatch, getState, { get }) => {
    const state = getState();
    const categoryId = catId || DEFAULT_CATEGORY;
    const params = {
        platform: PLATFORM,
        categoryId
    };

    const siteCode = getSiteCode(state);
    const filteredCategories = configSelector(state)?.filtered_categories;

    if ((siteCode === SITE_CODES.OLX_ID || siteCode === SITE_CODES.OLX_IN || filteredCategories?.length) && !isAutosPlatform) {
        params.features = 'inspection,olxautos';
    }

    const lang = getSelectedLangCode(state);

    if (lang) {
        params.lang = lang;
    }

    if (variantInfo && !variantInfo.disabled) {
        params.variant = variantInfo.variant;
    }

    const collection = state.filters.elements[categoryId];
    const isErrorVal = isError(state, categoryId, variantInfo);
    const isFetchingVal = isFetching(state, categoryId, variantInfo);

    if (!isEmpty(collection) || isErrorVal || isFetchingVal) {
        return Promise.resolve();
    }

    return dispatch(get(
        url,
        TYPE,
        params,
        BXP_REQUIRED_HEADER
    ));
};

export const addPopularRangeOptUsed = (categoryId, filterAttr, isUsed) => dispatch => {
    return dispatch({
        type: ADD_POPULAR_RANGE_USED,
        data: { categoryId, filterAttr, isUsed }
    });
};

export const addFiltersRecencyOrder = filterRecencyOrder => ({
    type: ADD_FILTERS_RECENCY_ORDER,
    data: { filterRecencyOrder }
});

export const clearFilterRecencyOrder = () => ({
    type: CLEAR_FILTERS_RECENCY_ORDER
});
