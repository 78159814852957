import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import withRouter from 'HOCs/withRouter';
import withConfig from 'HOCs/withConfig/withConfig';
import Default from './Default';
import Cars from './Cars';

export const Listing = ({ config, params: { categoryID }}) => ( // eslint-disable-line no-confusing-arrow
    `${categoryID}` === `${config.get('SEO', 'showMakeModelForCarCategory')}` ? (
        <Cars />
    ) : (
        <Default />
    )
);

Listing.propTypes = {
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired,
    params: PropTypes.shape({
        categoryID: PropTypes.string
    }).isRequired
};

export default compose(
    withRouter,
    withConfig
)(Listing);
