import '@olxid/olx-autos-landing-page/dist/Components/CTA/CTA.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/NumberPlateInputWithLink/NumberPlateInputWithLink.scss';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withRouter from 'HOCs/withRouter';
import { Carousel } from 'panamera-react-ui';
import { CTA } from 'olx-autos-landing-page';
import ItemCard from 'Components/Listing/components/ItemCard';
import { getItems } from 'Actions/items';
import { carouselItemsSelector } from 'Selectors/items';
import withConfig from 'HOCs/withConfig/withConfig';
import CookieManager from 'Helpers/cookies';
import { FACET_LIMIT } from 'Constants/listing';
import { ITEMS_SEARCH_SOURCE, APIVERSION } from 'Constants/items';
import css from './ItemsCarousel.scss';

export const ItemsCarousel = ({
    location,
    getItems,
    items,
    name,
    data: {
        cta: {
            buttons: [button]
        }
    },
    track,
    locationId,
    config
}) => {
    useEffect(() => {
        const { carCategory } = config.get('olxAutos') || {};

        getItems({
            itemsSource: ITEMS_SEARCH_SOURCE,
            facetLimit: FACET_LIMIT,
            cookies: CookieManager.getAllCookies(),
            location: {
                ...location,
                query: {
                    ...location.query,
                    sorting: 'desc-relevance'
                }
            },
            spellCheckFlag: true,
            apiVersion: APIVERSION.V4,
            longFiltersCategoryList: undefined,
            givenCategoryId: carCategory
        });
    }, [getItems, location, config]);

    if (!items || !items.length) {
        return null;
    }

    return (
        <>
            <Carousel>
                { items.map(item => (
                    <ItemCard
                        key={ `items-carousel-item-${item.id}` }
                        item={ item }
                        visualizationType={ 'big' }
                        className={ css.itemCard }
                        showInspectionTag
                    />

                ))}
            </Carousel>
            <div className={ css.btnWrapper }>
                <CTA
                    button={ button }
                    widgetKey={ name }
                    customClass={ css.seeListingBtn }
                    locationId={ locationId }
                    track={ track }
                />
            </div>
        </>
    );
};

ItemsCarousel.propTypes = {
    location: PropTypes.object.isRequired,
    getItems: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string.isRequired,
    track: PropTypes.func,
    locationId: PropTypes.string,
    data: PropTypes.object,
    config: PropTypes.object
};

ItemsCarousel.defaultProps = {
    getItems: () => {},
    config: {
        get: () => {}
    }
};
export const mapStateToProps = (state, ownProps) => ({
    items: carouselItemsSelector(state, ownProps)
});

export const mapDispatchToProps = {
    getItems
};

export default compose(
    withRouter,
    withConfig,
    connect(mapStateToProps, mapDispatchToProps),
    React.memo
)(ItemsCarousel);
