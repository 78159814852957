import React from 'react';
import PropTypes from 'prop-types';
import css from './ImageLoader.scss';

const ImageLoader = props => {
    let loadingElement = null;

    if (!props.loaded) {
        loadingElement = <div className={ `${css.imagePlaceholder}` }><div className={ `${css.image} ${css.animatedBackground}` } /></div>;
    }
    return (
        <React.Fragment>
            {loadingElement}
            {props.children}
        </React.Fragment>
    );
};

ImageLoader.propTypes = {
    children: PropTypes.object,
    loaded: PropTypes.bool
};

ImageLoader.defaultProps = {
    loaded: false
};

export default ImageLoader;
