import { createSelector } from 'reselect';
import { recursiveFindBy } from 'Helpers/array';
import { OLX_RENEW_MOBILE_NAME, OLX_RENEW_MOBILE_CAT_ALL_L2 } from 'Constants/constants';
import { getSelectedLangCode, getLocale } from '../selectors/config';

const navigationTreeSelector = ({ navigationTree }, otoPlusCategory = []) => {
    if (otoPlusCategory?.length && navigationTree?.data?.[0]?.id !== otoPlusCategory?.[0]?.id) {
        navigationTree?.data?.unshift({
            id: otoPlusCategory[0].id,
            name: otoPlusCategory[0].name
        });
    }
    return (navigationTree && navigationTree.data) || [];
};

const navigationTreeByIdSelector = createSelector(
    navigationTreeSelector,
    navigationTree => id => {
        if (!navigationTree || !navigationTree.length || !id) {
            return null;
        }

        return recursiveFindBy(navigationTree, 'id', id, 'sub_categories');
    }
);

const suggestedNavigationTreeCategoriesSelector = createSelector(
    navigationTreeByIdSelector,
    (_, { config }) => config && config.get('suggestedCategories'),
    (getCategoryById, suggested) => {
        if (!suggested) {
            return null;
        }
        return suggested.map(suggestId => getCategoryById(suggestId));
    }
);

const getLangCode = state => {
    const lang = getSelectedLangCode(state);
    const locale = getLocale(state);

    return lang || locale;
};
const updatedSuggestedNavigationTreeCategoriesSelector = (suggestedCategories, olxRenewCategory = [], state) => {
    const langCode = getLangCode(state);
    const olxRenew = olxRenewCategory && olxRenewCategory.length > 0 && olxRenewCategory.find(value => {
        return value.name === OLX_RENEW_MOBILE_NAME;
    });

    if (suggestedCategories && suggestedCategories.length > 0 && olxRenew?.data?.display_order?.pwa_web && olxRenew?.enabled && suggestedCategories.filter(item => item?.id == OLX_RENEW_MOBILE_NAME).length == 0) {
        suggestedCategories.splice((olxRenew.data.display_order.pwa_web - 1), 0, { name: olxRenew?.data?.[`category_name_${langCode}`], id: olxRenew?.data?.id, enabled: olxRenew?.enabled, redirection_url: olxRenew?.data?.redirection_url, sub_categories: [], category_id: olxRenew?.data?.category_id });
    }
    return suggestedCategories;
};

const updatedNavigationTreeSelector = (categories, olxRenewCategory = [], state) => {
    const langCode = getLangCode(state);
    const olxRenew = olxRenewCategory && olxRenewCategory.length > 0 && olxRenewCategory.find(value => {
        return value.name === OLX_RENEW_MOBILE_NAME;
    });
    const olxRenewL2 = olxRenewCategory && olxRenewCategory.length > 0 && olxRenewCategory.find(value => {
        return value.name === OLX_RENEW_MOBILE_CAT_ALL_L2;
    });

    if (olxRenew && Object.keys(olxRenew).length !== 0 && olxRenew?.data?.display_order?.pwa_mobile && olxRenew?.enabled && categories && categories.filter(item => item?.id == OLX_RENEW_MOBILE_NAME).length == 0) {
        categories.splice((olxRenew.data.display_order.pwa_mobile - 1), 0, { name: olxRenew?.data?.[`category_name_${langCode}`], id: olxRenew?.data?.id, enabled: olxRenew?.enabled, redirection_url: olxRenew?.data?.redirection_url, sub_categories: [], category_id: olxRenew?.data?.category_id });
    }

    if (olxRenewL2 && Object.keys(olxRenewL2).length !== 0 && olxRenewL2?.enabled && categories && categories.filter(item => item?.sub_categories[0]?.id == OLX_RENEW_MOBILE_CAT_ALL_L2).length == 0) {
        categories.map((item, index) => {
            if (item?.sub_categories[0]?.id !== OLX_RENEW_MOBILE_CAT_ALL_L2 && item?.id === olxRenewL2?.data?.parent_category_id) {
                categories[index]?.sub_categories?.unshift({
                    name: olxRenewL2?.data?.[`category_name_${langCode}`], id: olxRenewL2?.data?.id, enabled: olxRenewL2?.enabled, redirection_url: olxRenewL2?.data?.redirection_url?.pwa_mobile, category_id: olxRenewL2?.data?.category_id, category_name_postfix: olxRenewL2?.data[`category_name_postfix_${langCode}`]
                });
            }
        });
    }
    return categories;
};

const getOlxRenewCategoryForL2 = (olxRenewCategoryL2 = [], state) => {
    const langCode = getLangCode(state);
    const olxRenew = olxRenewCategoryL2 && olxRenewCategoryL2.length > 0 && olxRenewCategoryL2.find(value => {
        return value.name === OLX_RENEW_MOBILE_CAT_ALL_L2;
    });

    return { name: olxRenew?.data?.[`category_name_${langCode}`], id: olxRenew?.data?.id, enabled: olxRenew?.enabled, redirection_url: olxRenew?.data?.redirection_url?.pwa_desktop, category_id: olxRenew?.data?.category_id, category_name_postfix: olxRenew?.data?.[`category_name_postfix_${langCode}`], parent_category_id: olxRenew?.data?.parent_category_id };
};

export {
    navigationTreeSelector,
    navigationTreeByIdSelector,
    suggestedNavigationTreeCategoriesSelector,
    updatedSuggestedNavigationTreeCategoriesSelector,
    updatedNavigationTreeSelector,
    getOlxRenewCategoryForL2
};
