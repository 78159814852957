import { createSelector } from 'reselect';
import { getType } from 'Reducers/helpers/typeModifier';
import { MODEL_TYPE } from 'Reducers/olxAutos';
import { MODEL_URL } from 'Actions/olxAutos';
import { isEmpty } from 'Helpers/objects';
import { MARKETING_CAMPAIGNS_MAPPING, CAMPAIGN_DATA } from 'Constants/sellCarO2O';
import { queryStringToParams } from 'Helpers/url';
import { verifyRedirectionParameters } from 'Helpers/track';
import { getItem as getSessionStorageItem, setItem as setSessionStorageItem } from 'Helpers/sessionStorage';

export const getModelData = (state, selectedMake) => {
    const key = `${MODEL_URL}?make=${selectedMake}`;
    const type = getType(MODEL_TYPE, key);

    return isEmpty(state.olxAutos.model.collections[type]) ? []
        : state.olxAutos.model.collections[type].list;
};

export const getPopularBrands = state => {
    const popularList = state.olxAutos.popularBrands.data?.popularDataItems;

    return isEmpty(popularList) ? [] : popularList;
};

const getMarketingCampaigns = (queryRedirectionParams = {}, landingURL = '') => {
    const campaignsParams = {};
    const campaignData = { ...queryRedirectionParams, landingURL };
    let campaign_data = {};

    if (!getSessionStorageItem(CAMPAIGN_DATA) && !isEmpty(landingURL)) {
        setSessionStorageItem(CAMPAIGN_DATA, JSON.stringify(campaignData));
    }

    if (getSessionStorageItem(CAMPAIGN_DATA)) {
        campaign_data = JSON.parse(getSessionStorageItem(CAMPAIGN_DATA));
    }
    const queryJSON = Object.keys(campaign_data);

    if (queryJSON?.length) {
        for (const key of MARKETING_CAMPAIGNS_MAPPING) {
            if (campaign_data[key]) {
                campaignsParams.campaign_click_ad = key;
                campaignsParams.campaign_click_id = campaign_data[key];
            }
        }

        campaignsParams.campaign_id = campaign_data['campaign_id'];
        campaignsParams.acquisition_channel_campaign_ad_group = campaign_data.group;
        campaignsParams.acquisition_channel_territory = campaign_data.territory;
        campaignsParams.country = campaign_data.country;
        campaignsParams.acquisition_channel_device = campaign_data.device;
        campaignsParams.acquisition_channel_network = campaign_data.network;
        campaignsParams.match_type = campaign_data.match_type;
        campaignsParams.ad_id = campaign_data['ad_id'];
        campaignsParams.target_id = campaign_data['target_id'];
        campaignsParams.group_id = campaign_data['group_id'];
        campaignsParams.Ipurl = campaign_data.landingURL;
        campaignsParams.campaign_type = campaign_data['campaign_type'];
    }
    return campaignsParams;
};

export const getQueryRedirectionParams = createSelector(
    landingSearchURL => landingSearchURL,
    (_, routerLocation) => routerLocation,
    (landingSearchURL = '', routerLocation = {}) => {
        let queryRedirectionParams = {};
        let campaignsParams = {};

        try {
            queryRedirectionParams = (routerLocation.query && Object.keys(routerLocation.query).length) ? routerLocation.query : queryStringToParams(landingSearchURL.replace('?', ''));
            campaignsParams = getMarketingCampaigns(queryRedirectionParams, routerLocation.search || landingSearchURL);
        }
        catch (error) {
            queryRedirectionParams = queryStringToParams(landingSearchURL.replace('?', ''));
            campaignsParams = getMarketingCampaigns(queryRedirectionParams, landingSearchURL);
        }

        return {
            ...verifyRedirectionParameters(queryRedirectionParams || {}), ...campaignsParams
        };
    }
);
