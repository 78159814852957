import { urls } from 'Reducers/users';
import { paramsToQueryString } from 'Helpers/url';
import * as objectsHelper from 'Helpers/objects';
import { getFilledCollection, callHasBeenMadeForCollection } from 'Reducers/componentHelpers';
import {
    getLoggedUser
} from 'Selectors/user';
import { getType } from 'Reducers/helpers/typeModifier';

export const isFetching = (state, url) => {
    if (state.users.isFetching[getType('USERS', url)] === undefined) {
        return undefined;
    }

    return Boolean(state.users.isFetching[getType('USERS', url)]);
};

const getUrl = (state, url, queryParam = null) => {
    if (!state) {
        return {};
    }
    const lang = state.api && state.api.queryParam && state.api.queryParam.enableMultiLang && state.api.queryParam.lang;

    if (lang) {
        queryParam = queryParam || {};
        queryParam.lang = lang;
    }

    if (queryParam) {
        url = `${url}?${paramsToQueryString(queryParam)}`;
    }

    return url;
};

export const userSelector = (state, userId) =>
    state.users.elements[userId];

export const isGetUserFetching = (state, userId) => {
    const url = getUrl(state, urls.getExistingUser(userId));

    return isFetching(state, url);
};

export const userFollowingSelector = (state, userId) => {
    const myUser = getLoggedUser(state);

    if (!userId || !myUser.id) {
        return false;
    }
    const url = getUrl(state, urls.userIsFollowing(myUser.id, userId));

    return state.users.collections[getType('USERS', url)];
};

export const isUserIsFollowingFetching = (state, userId) => {
    const myUser = getLoggedUser(state);

    if (!userId || !myUser) {
        return false;
    }
    const url = getUrl(state, urls.userIsFollowing(myUser.id, userId));

    return isFetching(state, url);
};

export const isUserFollowingFetching = state => {
    const myUser = getLoggedUser(state);

    if (objectsHelper.isEmpty(myUser)) {
        return false;
    }
    const url = getUrl(state, urls.followUser(myUser.id));

    return isFetching(state, url);
};

export const isUserUnFollowingFetching = state => {
    const myUser = getLoggedUser(state);

    if (objectsHelper.isEmpty(myUser)) {
        return false;
    }
    const url = getUrl(state, urls.unFollowUser(myUser.id));

    return isFetching(state, url);
};

export const metaSelector = (state, userId) => {
    const url = getUrl(state, urls.getMeta(userId));

    return state.users.collections[getType('USERS', url)];
};

export const friendsInCommonSelector = (state, userId) => {
    const url = getUrl(state, urls.getFriendsInCommon(userId), { query: { social_network: 'platform' }});

    return getFilledCollection('USERS', state.users, url);
};

export const isFriendsInCommonFetching = (state, userId) => {
    if (!userId) {
        return false;
    }
    const url = getUrl(state, urls.getFriendsInCommon(userId), { query: { social_network: 'platform' }});

    return isFetching(state, url);
};

export const callHasBeenMadeForFriendsInCommon = (state, userId) => {
    const lang = state.api && state.api.queryParam && state.api.queryParam.enableMultiLang && state.api.queryParam.lang;
    const params = {
        query: { social_network: 'platform' }
    };

    if (!userId) {
        return false;
    }
    if (lang) {
        params.lang = lang;
    }

    return callHasBeenMadeForCollection(state.users, urls.getFriendsInCommon(userId), params);
};

export const followersSelector = (state, userId) => {
    if (!userId) {
        return [];
    }
    const url = getUrl(state, urls.getFollowers(userId));

    return getFilledCollection('USERS', state.users, url);
};

export const isFollowersFetching = (state, userId) => {
    if (!userId) {
        return false;
    }
    const url = getUrl(state, urls.getFollowers(userId));

    return isFetching(state, url);
};

export const followingSelector = (state, userId) => {
    if (!userId) {
        return [];
    }
    const url = getUrl(state, urls.getFollowing(userId));

    return getFilledCollection('USERS', state.users, url);
};

export const isFollowingFetching = (state, userId) => {
    if (!userId) {
        return false;
    }
    const url = getUrl(state, urls.getFollowing(userId));

    return isFetching(state, url);
};

export const findUserSelector = state => {
    const url = getUrl(state, urls.findUserV2);

    return state.users.collections[getType('USERS', url)];
};

export const getUserImagesDataSelector = (state, userId, type) => {
    if (!userId) {
        return [];
    }
    const url = getUrl(state, urls.getUserImagesData(userId, type));

    return state.users.collections[getType('USERS', url)];
};
