export const ORIGINAL = 'original';

export const SUGGESTED = 'suggested';

export const LISTING_TYPES = {
    ITEMS: 'items',
    RE_PORJECTS: 'reProjects'
};

export const FACET_LIMIT = 100;
export const trustTagHash = '#trust-tag-cars';
export const ITEM_CARD_TYPE = {
    FEED: 'feed',
    LISTING: 'listing'
};
export const DEFAULT_SIZE_BY_VISUALIZATION = {
    list: 's=272x0',
    grid: 's=272x0',
    big: 's=644x0'
};

export const listingUrlRegex = /^\/([\w]+\/)?([\w\d-]+_g[\d]+\/)?([\w\d-]+_c([\d]+))(\/q-.+)?(\/)?$/;

export const LISTING_BODY_TYPE = {
    MAIN: 'main',
    RELAXED: 'relaxed'
};
