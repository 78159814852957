import { MAKE_TYPE, MODEL_TYPE, POPULAR_BRANDS_TYPE } from 'Reducers/olxAutos';
import { getModelData } from 'Selectors/olxAutos';
import { isEmpty } from 'Helpers/objects';
import { PLATFORM } from 'Constants/device.APP_TARGET';

const MAKE_URL = '/api/rocket/valuate/make';
const POPULAR_BRANDS_URL = '/api/bxp/popular-data';

export const MODEL_URL = '/api/rocket/valuate/model';

export const fetchModel = make => (dispatch, getState, { get }) => {
    const state = getState();

    const modelData = getModelData(state, make);

    if (!isEmpty(modelData)) {
        return Promise.resolve(modelData);
    }

    return dispatch(get(
        MODEL_URL,
        MODEL_TYPE,
        {
            make
        },
        { 'x-panamera-client-id': `web-${PLATFORM}` }
    ));
};

export const fetchMake = () => (dispatch, getState, { get }) => {
    const state = getState();
    const makeData = state.olxAutos.make.data;

    if (!isEmpty(makeData)) {
        return Promise.resolve(makeData);
    }

    return dispatch(get(
        MAKE_URL,
        MAKE_TYPE,
        null,
        { 'x-panamera-client-id': `web-${PLATFORM}` }
    ));
};

export const fetchPopularBrands = ({ categoryId, locationId }) => (dispatch, getState, { get }) => {
    const state = getState();
    const data = state.olxAutos.popularBrands.data;

    if (!isEmpty(data)) {
        return Promise.resolve(data);
    }

    return dispatch(get(
        POPULAR_BRANDS_URL,
        POPULAR_BRANDS_TYPE,
        {
            locationId,
            attribute: 'make',
            flow: 'LANDING',
            platform: `web-${PLATFORM}`,
            category: categoryId
        },
        { 'x-panamera-client-id': `web-${PLATFORM}` }
    ));
};
